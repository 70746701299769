<template>
  <v-app>
    <Loader :is-visible="loading" />

    <!-- <v-navigation-drawer
      v-model="drawer"
      v-if="!this.$route.path.includes('login')"
      temporary
      fixed
    >
      <v-list dense>
        <v-list-item to="/indicadores/game">
          <v-list-item-action>
            <v-icon>mdi-account-multiple</v-icon>
          </v-list-item-action>
          <v-list-item-content>
            <v-list-item-title>Indicadores da Gameficação</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-list-item to="/indicadores/primarios" v-if="this.$store.state.uf === 'SC'">
          <v-list-item-action>
            <v-icon>mdi-account-multiple</v-icon>
          </v-list-item-action>
          <v-list-item-content>
            <v-list-item-title>Indicadores Primários</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-list-item to="/indicadores/execucao" v-if="this.$store.state.uf === 'SC'">
          <v-list-item-action>
            <v-icon>mdi-account-multiple</v-icon>
          </v-list-item-action>
          <v-list-item-content>
            <v-list-item-title>Indicadores de Execução</v-list-item-title>
          </v-list-item-content>
        </v-list-item>

        <v-list-item @click="logout">
          <v-list-item-action>
            <v-icon>mdi-logout-variant</v-icon>
          </v-list-item-action>
          <v-list-item-content>
            <v-list-item-title>Sair</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list>
    </v-navigation-drawer> -->

    <v-app-bar v-if="!this.$route.path.includes('login')" app class="primary">
      <!-- <v-app-bar-nav-icon @click.stop="drawer = !drawer"></v-app-bar-nav-icon> -->
      <v-toolbar-title class="headline">
        <template v-if="!isSala">
          <span>
            Painel de Indicadores -

            <img
              src="./assets/img/logo_nacional.png"
              style="width: 120px; margin-left: 10px"
            />
          </span>
          <img
            src="./assets/img/sebrae.png"
            style="width: 83px; margin-left: 10px"
          />
        </template>
        <template v-else>
          <img
            src="./assets/img/sebrae.png"
            style="width: 83px; margin-right: 10px"
          />
          <selo-logo></selo-logo>
          <div class="infos-cidade">
            <template v-if="isSala"> </template>
            <div>{{ getFirstName() }}</div>
            <div>|</div>
            <div class="buttonSair" @click="logout">sair</div>
          </div>
        </template>
      </v-toolbar-title>
      <v-spacer />
    </v-app-bar>

    <v-content>
      <router-view />
    </v-content>
  </v-app>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import Loader from "@/components/Loader";
import { atualUfDefinition } from "./helpers/utils";
import SeloLogo from "./components/seloLogo/seloLogo.vue";

export default {
  name: "App",
  components: {
    Loader,
    SeloLogo,
  },
  computed: {
    ...mapGetters([
      "isLogged",
      "isAuthenticated",
      "isConsultor",
      "isAdmin",
      "loading",
      "user",
      "uf",
      "logo",
      "svg",
      "isSala",
    ]),
  },
  created() {
    this.mudaUf();
  },
  data() {
    return {
      drawer: false,
      estado: process.env.VUE_APP_ESTADO,
      logouf: process.env.VUE_APP_LOGO,
    };
  },
  methods: {
    /** Não esquecer de mapear no mapActions o nome da store antes de setar ele no mudaUF */
    ...mapActions([
      "setToken",
      "setUser",
      "setAdmin",
      "setExpired",
      "setUf",
      "setLogo",
      "setSvg",
      "setIsSala",
      "setForcedLogout",
    ]),
    getFirstName() {
      var user = JSON.parse(localStorage.getItem("user"));
      if (user) return user.nome.split(" ")[0];

      return "";
    },
    mudaUf() {
      this.setUf(atualUfDefinition.uf);
      this.setIsSala(atualUfDefinition.isSala);

      this.setLogo(atualUfDefinition.logo);
      this.setSvg(atualUfDefinition.svg);
      setInterval(function () {
        document.documentElement.style.setProperty(
          "--corPrimaria",
          atualUfDefinition.corPrimaria
        );
      }, 100);

      if (atualUfDefinition.isSala) {
        document.title = "Painel - Selo Sala do Empreendedor";
        document.getElementById("favicon").href =
          "https://slide1-images.s3.amazonaws.com/public/favicon/selo.ico";
        document
          .querySelector('meta[property="og:title"]')
          .setAttribute("content", "Painel - Selo Sala do Empreendedor");
      } else {
        document.title = "Painel de Indicadores - Cidade Empreendedora";
        document.getElementById("favicon").href =
          "https://slide1-images.s3.amazonaws.com/public/favicon/ce.ico";
        document
          .querySelector('meta[property="og:title"]')
          .setAttribute(
            "content",
            "Painel de Indicadores - Cidade Empreendedora"
          );
      }
    },
    enableInterceptor() {
      this.$http.interceptors.request.use(
        (config) => {
          return config;
        },
        (error) => {
          return Promise.reject(error);
        }
      );
      this.$http.interceptors.response.use(
        (response) => {
          return response;
        },
        (err) => {
          if (err.response.status === 401) {
            this.setExpired(true);
            this.$notification.error(
              "Sessão expirada, por favor entre novamente",
              { timer: 10, ignoreDuplicates: true }
            );
            this.$store.dispatch("logout").then(() => {
              this.$router.push("/login");
            });
          }
          return Promise.reject(err);
        }
      );
    },
    logout() {
      this.setForcedLogout(true);
      this.$store.dispatch("logout").then(() => {
        this.$router.push("/auth/login");
      });
    },
  },
  mounted() {
    this.enableInterceptor();
    this.mudaUf();
  },
};
</script>

<style lang="scss">
@import "./style.scss";
@import "@/variables.scss";

.painel {
  margin-right: 10px;
  color: $cor-selo-texto !important;
}

.infos-cidade {
  margin-left: auto;
  display: flex;
  column-gap: 1rem;
  margin-right: 1rem;
  color: #fff;
  font-weight: 500;
  font-size: 14px;
  align-items: center;

  .icon-help {
    cursor: pointer;
  }
  .help {
    color: #fff;
  }

  .help:hover {
    background-color: #284395 !important;
    padding: 5px 10px;
    border-radius: 5px;
    color: #fff !important;
    .icon-help {
      color: #fff !important;
    }
  }

  .buttonSair {
    background-color: #5c8bb9;
    color: white;
    padding: 5px 15px;
    border-radius: 5px;
    cursor: pointer;
  }

  .buttonSair:hover {
    background-color: #284395;
  }
}
</style>
