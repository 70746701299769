import Vue from 'vue'
import Router from 'vue-router'
import routes from './routes'

import BeforeEach from './BeforeEach.js'

Vue.use(Router)

const router = new Router({
  base: process.env.BASE_URL,
  routes: routes
})


router.beforeEach(BeforeEach)

export default router
