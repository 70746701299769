export const routes = [
  {
    path: '/auth/login',
    component: () => import(/* webpackChunkName: "auth" */ './components/Login'),
    name: 'auth.login',
    meta: {
      requiresNoAuth: true
    }
  },
  {
    path: '/auth/primeiro-acesso',
    component: () => import(/* webpackChunkName: "user" */ './components/FirstAccess'),
    name: 'update.first',
    props: true,
    meta: {
      requiresNoAuth: true
    }
  },
  {
    path: '/auth/lost-password',
    component: () => import(/* webpackChunkName: "auth" */ './components/LostPassword'),
    name: 'auth.lost.password',
    meta: {
      requiresNoAuth: true
    }
  },
  {
    path: '/auth/recover-password/:token',
    component: () => import(/* webpackChunkName: "auth" */ './components/RecoverPassword'),
    props: true,
    name: 'auth.token',
    meta: {
      requiresNoAuth: true
    }
  }
]
