import '@mdi/font/css/materialdesignicons.css'
import Vue from 'vue'
import Vuetify from 'vuetify/lib'
import { atualUfDefinition } from '../helpers/utils'

// import colors from 'vuetify/lib/util/colors'

Vue.use(Vuetify)

export default new Vuetify({
  icons: {
    iconfont: 'mdi'
  },
  theme: {
    themes: {
      light: {
        primary: atualUfDefinition.corPrimaria,
        secondary: '#F4D35E',
        accent: '#4D4D4D',
        error: '#FF5252',
        info: '#2196F3',
        success: '#4CAF50'
      }
    }
  }
})
