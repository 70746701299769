import baseAPI from '@/http'

const router = 'api/indicador/indicadorMunicipio'

export const indicadorMunicipioAPI = {
  create: (indicador) => baseAPI.post(router, indicador),
  update: (indicador) => baseAPI.put(router, indicador),
  delete: (id) => baseAPI.delete(`${router}/${id}`),
  list: () => baseAPI.get(router),
  get: (id) => baseAPI.get(`${router}/${id}`),
  // getMontaIndicador: (subGrupoId, codigosIBGE) => baseAPI.get(`${router}/getMontaIndicador/${subGrupoId}/${codigosIBGE}`),
  getMontaIndicador: (indicadorSubgrupoId, codigosIBGE) => baseAPI.get(`${router}/getMontaIndicador`, { params: { indicadorSubgrupoId, codigosIBGE } }),
  getindicadoresbysubgrupo: (subGrupoId) => baseAPI.get(`${router}/getindicadoresbysubgrupo/${subGrupoId}`),
  getIndicadoresByMunicipioGroups: (municipioId, indicadorSubgrupoId) => baseAPI.get(`${router}/get-municipio-indicadorsubgrupo/${municipioId}/${indicadorSubgrupoId}`),

}
