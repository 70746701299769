<template>
  <v-dialog
    v-model="dialog"
    persistent
    max-width="600"
  >
    <template v-slot:activator="{ on }">
      <v-btn
        outlined
        :color="color"
        class="ml-3"
        v-on="on"
      >
        <v-icon>{{ icon }}</v-icon><span v-if="edit === false">Indicador</span>
      </v-btn>
    </template>
    <v-card>
      <v-card-title
        class="modal-header elevation-10"
      >
        <v-row>
          <v-col>
            <slot name="title" />
          </v-col>
          <v-col class="text-right">
            <v-icon
              color="white"
              @click="dialog = false"
            >
              mdi-window-close
            </v-icon>
          </v-col>
        </v-row>
      </v-card-title>
      <v-card-text>
        <v-row>
          <v-col sm="6">
            <v-text-field
              v-model="payload.nome"
              label="Indicador"
              name="nome"
              type="text"
              required
            />
          </v-col>
        </v-row>
      </v-card-text>
      <v-card-actions>
        <div class="flex-grow-1" />
        <v-btn
          color="error"
          outlined
          text
          @click="dialog = false"
        >
          Fechar
        </v-btn>
        <v-btn
          color="info"
          outlined
          text
          @click="onConfirm"
        >
          Salvar
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { indicadorAPI } from '../API/indicadorAPI'

export default {
  name: 'AppModalInsert',
  components: {

  },
  props: {
    object: { type: Object, required: false },
    edit: { type: Boolean, required: false, default: false },
    icon: { type: String, required: true },
    color: { type: String, required: true },
    subgrupoId: { type: Number, required: true }
  },
  data () {
    return {
      payload: {},
      dialog: false
    }
  },
  mounted () {
    if (this.object && this.edit === true) {
      this.payload = this.object
    }
  },
  methods: {
    onConfirm () {
      if (this.grupoId !== 0) {
        this.payload.uf = this.$store.state.uf
        this.payload.indicadorSubgrupoId = this.subgrupoId
        if (this.edit === true) {
          indicadorAPI.update(this.payload)
            .then(() => {
              this.dialog = false
              this.$emit('on-save')
              this.payload.nome = null
            })
            .catch()
        } else {
          indicadorAPI.create(this.payload)
            .then(() => {
              this.dialog = false
              this.$emit('on-save')
              this.payload.nome = null
            })
            .catch()
        }
      } else {
        this.$notification.error('Erro')
      }
    }
  }
}
</script>
