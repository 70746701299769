<template>
  <v-container>
    <v-dialog
      v-model="exibeModal"
      scrollable
    >
      <v-card>
        <v-card-title
          class="modal-header elevation-10"
        >
          <v-col>
            <slot name="title">
              Adicionar Indicador
            </slot>
          </v-col>
          <v-col class="text-right">
            <v-icon
              color="white"
              @click="$emit('closeModal')"
            >
              mdi-window-close
            </v-icon>
          </v-col>
        </v-card-title>
        <v-card-text>
          <v-row>
            <v-col cols="4">
              <v-text-field
                v-model="indicador.nome"
                label="Nome"
              />
            </v-col>
            <v-col cols="4">
              <v-text-field
                v-model="indicador.fonte"
                label="Fonte"
              />
            </v-col>
            <v-col cols="4">
              <v-text-field
                v-model="indicador.relatorio"
                label="Relatorio"
              />
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="4">
              <v-text-field
                v-model="indicador.texto"
                label="Texto"
              />
            </v-col>
            <v-col cols="4">
              <v-select
                v-model="indicador.indicadorSubgrupoId"
                :items="subgrupos"
                item-text="nome"
                item-value="indicadorSubgrupoId"
                label="Subgrupos"
                placeholder="Selecione um Subgrupo"
              />
            </v-col>
            <v-col cols="4">
              <v-btn
                class="primary"
                @click="salvar"
              >
                Salvar
              </v-btn>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import { indicadorAPI, indicadorSubgrupoAPI } from '@/modules/indicadores'
import { mapActions } from 'vuex'

export default {
  props: {
    exibeModal: { type: Boolean, required: true }
  },
  data () {
    return {
      indicador: {},
      subgrupos: []
    }
  },
  methods: {
    ...mapActions(['setLoading']),
    getIndicadores () {
      indicadorSubgrupoAPI.list()
        .then(resp => {
          this.subgrupos = resp.data
        })
        .catch(() => {
          this.$notification.error('Erro ao listar dados')
        })
    },
    salvar () {
      this.setLoading(true)
      indicadorAPI.create(this.indicador)
        .then(() => {
          this.$emit('closeModal')
          this.$notification.success('Salvo com sucesso')
          this.setLoading(false)
        })
        .catch(() => {
          this.setLoading(false)
          this.$notification.error('Erro ao salvar indicador')
        })
    }
  },
  watch: {
    exibeModal: function (value) {
      if (value) {
        this.getIndicadores()
      }
    }
  }
}
</script>

<style lang="scss">
.rowIndicadoresMunicipais {
  margin-left: -24px;
  margin-right: -24px;
  margin-top: 20px;
  text-align: center;
  color: #fff;
  background-color: #086a87;
}
</style>
