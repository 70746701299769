export default {
  clear () {
    localStorage.clear()
  },
  setItem (key, value) {
    localStorage.setItem(key, JSON.stringify(value))
  },
  getItem (key) {
    return JSON.parse(localStorage.getItem(key))
  },
  removeItem (key) {
    return localStorage.removeItem(key)
  },
  isLogged () {
    return typeof localStorage.getItem('token') === 'string'
  },
  isAdmin () {
    return Number(this.getUser().pro_id) === 1
  },
  isCityUser () {
    return Number(this.getUser().pro_id) === 3
  },
  getUser () {
    return JSON.parse(localStorage.getItem('user'))
  }
}
