<template>
  <v-container
    id="ur-list"
  >
    <v-row>
      <v-col
        class="text-right"
      >
        <app-modal-insert
          :edit="false"
          :object="null"
          :color="'success'"
          :icon="'mdi-plus-circle'"
          @on-save="load"
        >
          <template
            v-slot:title
            :edit="false"
          >
            Cadastrar
          </template>
        </app-modal-insert>
      </v-col>
    </v-row>

    <v-row>
      <v-col>
        <v-data-table
          :headers="headers"
          :items="payload"
          class="elevation-1"
          hide-default-footer
          disable-pagination
        >
          <template v-slot:item.nome="{ item }">
            <span>
              {{ item.nome }}
            </span>
          </template>

          <template v-slot:item.acoes="{ item }">
            <v-row>
              <v-col
                cols="1"
              >
                <app-modal-insert
                  :object="item"
                  :edit="true"
                  :color="'warning'"
                  :icon="'mdi-pencil'"
                  @on-save="load"
                >
                  <template v-slot:title>
                    Editar Grupo
                  </template>
                </app-modal-insert>
              </v-col>
            </v-row>
          </template>

          <template v-slot:item.acoes_delete="{ item }">
            <v-row>
              <v-col
                cols="1"
              >
                <app-modal-delete
                  :object="item"
                  @on-save="load"
                >
                  <template v-slot:title>
                    Deletar
                  </template>
                </app-modal-delete>
              </v-col>

              <v-col
                cols="1"
              />
            </v-row>
          </template>

          <template slot="no-data">
            <span>
              Nada retornado
            </span>
          </template>
        </v-data-table>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import AppModalInsert from './ModalInsert'
import { grupoIndicadorAPI } from '../API/grupoIndicadorAPI'
import { mapActions, mapGetters } from 'vuex'
import AppModalDelete from './../components/ModalDelete'

export default {
  components: {
    AppModalInsert,
    AppModalDelete
  },
  data () {
    return {
      headers: [
        { text: 'Grupo', value: 'nome' },
        { text: 'Editar', value: 'acoes', sortable: false },
        { text: 'Excluir', value: 'acoes_delete', sortable: false }
      ],
      payload: []
    }
  },
  methods: {
    ...mapActions([
      'setLoading',
      'setConsultorCondutorMunicipio',
      'setConsultorEspecialistaMunicipio',
      'setAnalistaMunicipio',
      'setADMunicipio'
    ]),
    load () {
      this.setLoading(true)
      grupoIndicadorAPI.list()
        .then(resp => {
          this.payload = resp.data
          this.setLoading(false)
        })
        .catch(() => {
          this.setLoading(false)
          this.$notification.error('Erro ao obter URs')
        })
    }
  },
  mounted () {
    this.load()
  },
  computed: {
    ...mapGetters([
      'isAdmin',
      'isConsultorCondutorMunicipio',
      'isConsultorEspecialistaMunicipio',
      'isADMunicipio',
      'isAnalistaMunicipio',
      'isAuthenticated',
      'isSPFesp',
      'isSPFespEspecial',
      'isSPCoordenador'
    ])
  }
}
</script>
