<template>
  <v-container>
    <v-dialog v-model="exibeModal" scrollable v-if="!loading">
      <v-card>
        <v-card-title class="modal-header elevation-10">
          <v-col>
            <slot name="title">
              {{ municipio.nome }}
            </slot>
          </v-col>
          <v-col class="text-right">
            <v-icon color="white" @click="$emit('closeModal')">
              mdi-window-close
            </v-icon>
          </v-col>
        </v-card-title>

        <v-card-text>
          <v-row>
            <v-col>
              <span v-if="municipio.regiao">
                <b>Região: </b> {{ municipio.regiao.nome }}
              </span>
              <span v-if="municipio.projeto">
                <b>Projeto: </b> {{ municipio.projeto }}
              </span>
            </v-col>
          </v-row>

          <v-row class="rowIndicadoresMunicipais">
            <v-col>
              <h3>INDICADORES PRIMÁRIOS</h3>
            </v-col>
          </v-row>

          <v-row>
            <v-col>
              <v-tabs v-model="tab" background-color="primary" vertical left>
                <v-tab
                  v-for="item in indicadoresSubGrupo"
                  :key="item.indicadorGrupoId"
                  center
                  @click="loadIndicadoresForSubGroup(item)"
                >
                  {{ item.nome }}
                </v-tab>

                <v-tab-item
                  v-for="item in indicadoresSubGrupo"
                  :key="item.indicadorId"
                >
                  <v-row>
                    <v-col>
                      <v-data-table
                        :headers="cabecalhoIndicadores"
                        :items="indicadores"
                        :disable-pagination="true"
                        :hide-default-footer="true"
                      >
                        <template slot="no-data">
                          <div>Não há indicadores para esse subgrupo</div>
                        </template>
                      </v-data-table>
                      <!-- <table>
                        <thead>
                          <th
                            v-for="indicador in cabecalhoIndicadores"
                            :key="indicador.text"
                          >
                            {{ indicador.text }}
                          </th>
                        </thead>

                        <tbody>
                          <tr>
                            <td
                              v-for="indicador in cabecalhoIndicadores"
                              :key="indicador.value"
                            >
                              {{ indicadoresMunicipio[indicador.value] }}
                            </td>
                          </tr>
                        </tbody>
                      </table> -->
                    </v-col>
                  </v-row>
                </v-tab-item>
              </v-tabs>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import { indicadorSubgrupoAPI } from "@/modules/indicadores";
import { indicadorMunicipioAPI } from "@/modules/indicadores";
import { mapGetters, mapActions } from "vuex";

export default {
  props: {
    municipio: { type: Object, required: false },
    exibeModal: { type: Boolean, required: true }
  },
  data() {
    return {
      indicadoresSubGrupo: [],
      indicadores: [],
      tab: {},
      cabecalhoIndicadores: [
        { text: "Nome do Indicador", value: "indicador.nome" },
        { text: "Valor", value: "valor" }
      ]
    };
  },
  computed: {
    ...mapGetters(["loading"])
  },
  watch: {
    exibeModal: function(value) {
      if (value) {
        this.loadSubgroups();
      }
    }
  },
  methods: {
    ...mapActions(["setLoading"]),
    async loadSubgroups() {
      this.setLoading(true);
      await indicadorSubgrupoAPI
        .list()
        .then(indicador => {
          this.setLoading(false);
          this.indicadoresSubGrupo = indicador.data;
          this.loadIndicadoresForSubGroup(this.indicadoresSubGrupo[0]);
        })
        .catch(this.setLoading(false));
    },
    async loadIndicadoresForSubGroup(indicadorSubgrupoId) {
      await indicadorMunicipioAPI
        .getIndicadoresByMunicipioGroups(
          this.municipio.municipioId,
          indicadorSubgrupoId.indicadorSubgrupoId
        )
        .then(data => {
          this.indicadores = data.data;
          // console.log(this.indicadores);
        })
        .catch(this.setLoading(false));
    }
  }
};
</script>

<style lang="scss">
.v-tabs-slider-wrapper {
  width: 0% !important;
}
.rowIndicadoresMunicipais {
  margin-left: -24px;
  margin-right: -24px;
  margin-top: 20px;
  text-align: center;
  color: #fff;
  background-color: #086a87;
}
</style>