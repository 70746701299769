<template>
  <v-select
    id="municipio-select"
    v-model="filter.municipio"
    item-text="nome"
    item-value="municipioId"
    :items="municipios"
    :label="isSala ? 'Parceiros' : 'Municípios'"
    placeholder="Selecione..."
    :return-object="true"
    :disabled="disabled"
    @change="emitValue"
    :multiple="isMultiple"
    :solo="solo"
    :loading="loading"
    hide-details="auto"
  >
    <template slot="no-data">
      <div v-if="loading">
        <v-col align-self="center"
          ><div class="progress" v-if="loading">
            <v-progress-circular
              indeterminate
              color="primary"
            ></v-progress-circular></div
        ></v-col>
      </div>
      <div v-else>Nenhum Município retornado</div>
    </template>
  </v-select>
</template>

<script>
import { municipioAPI } from "@/modules/municipio";
import { mapGetters } from "vuex";

export default {
  name: "MunicipioCombobox",
  props: {
    filterMunicipios: { type: Array, default: () => [] },
    filter: { type: Object, required: true },
    disabled: { type: Boolean, required: false, default: false },
    solo: { type: Boolean, required: false, default: false },

    isMultiple: { type: Boolean, required: false, default: false },
  },
  computed: {
    ...mapGetters(["isSala"]),
  },
  data() {
    return {
      todomunicipios: [],
      municipios: [],
      loading: true,
    };
  },
  watch: {
    filter: {
      handler: function (newValue) {
        this.filtrarMunicipios(newValue);
      },
      deep: true,
    },
  },
  mounted() {
    this.list();
  },
  methods: {
    emitValue(e) {
      this.$emit("changeMunicipio", e);
    },
    filtrarMunicipios(newValue) {
      this.municipios = this.todomunicipios;
      if (newValue.uf && newValue.uf.length) {
        this.municipios = this.municipios.filter((item) =>
          newValue.uf.some((uf) => uf.uf === item.uf || uf === item.uf)
        );
      }
      if (newValue.relacionamento && newValue.relacionamento.length) {
        this.municipios = this.municipios.filter((item) =>
          newValue.relacionamento.some((r) => r.statusId === item.statusId)
        );
      }
      if (newValue.contrato && newValue.contrato.length) {
        this.municipios = this.municipios.filter((item) =>
          newValue.contrato.some(
            (r) => r.statusContratoId === item.statusContratoId
          )
        );
      }
    },
    list() {
      municipioAPI
        .list(this.$store.state.uf)
        .then((resp) => {
          this.todomunicipios = resp.data;
          this.municipios = resp.data;
          this.filtrarMunicipios(this.filter);
          this.loading = false;
        })
        .catch(() => {
          this.loading = false;
          this.$notification.error("Erro ao listar municípios");
        });
    },
  },
};
</script>
