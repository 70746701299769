import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VTextField } from 'vuetify/lib/components/VTextField';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c(VDialog,{attrs:{"persistent":"","max-width":"600"},scopedSlots:_vm._u([{key:"activator",fn:function({ on }){return [_c(VBtn,_vm._g({staticClass:"ml-3",attrs:{"outlined":"","color":_vm.color}},on),[_c(VIcon,[_vm._v(_vm._s(_vm.icon))]),(_vm.edit === false)?_c('span',[_vm._v("Indicador")]):_vm._e()],1)]}}]),model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c(VCard,[_c(VCardTitle,{staticClass:"modal-header elevation-10"},[_c(VRow,[_c(VCol,[_vm._t("title")],2),_c(VCol,{staticClass:"text-right"},[_c(VIcon,{attrs:{"color":"white"},on:{"click":function($event){_vm.dialog = false}}},[_vm._v(" mdi-window-close ")])],1)],1)],1),_c(VCardText,[_c(VRow,[_c(VCol,{attrs:{"sm":"6"}},[_c(VTextField,{attrs:{"label":"Indicador","name":"nome","type":"text","required":""},model:{value:(_vm.payload.nome),callback:function ($$v) {_vm.$set(_vm.payload, "nome", $$v)},expression:"payload.nome"}})],1)],1)],1),_c(VCardActions,[_c('div',{staticClass:"flex-grow-1"}),_c(VBtn,{attrs:{"color":"error","outlined":"","text":""},on:{"click":function($event){_vm.dialog = false}}},[_vm._v(" Fechar ")]),_c(VBtn,{attrs:{"color":"info","outlined":"","text":""},on:{"click":_vm.onConfirm}},[_vm._v(" Salvar ")])],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }