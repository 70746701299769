import Auth from '@/Auth.js'
import moment from "moment";

export default (to, from, next) => {
  Auth.setItem('last_route', from.path)

  if (to.matched.some(record => record.meta.requiresAuth)) {

    if (!Auth.isLogged() || isUserExpired()) {
      next({
        path: '/auth/login'
      })
    } else {
      next()
    }
  } else {
    next()
  }
}

function isUserExpired() {
  var user = JSON.parse(localStorage.getItem("user"))
  var expirationDate = moment(user.expiration, 'YYYY-MM-DD HH:mm:ss')
  var now = moment();

  if (now.isBefore(expirationDate))
    return false

  return true
}