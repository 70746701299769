import baseAPI from '@/http'

const router = 'api/indicador/IndicadorSubGrupo'

export const subGrupoIndicadorAPI = {
  create: (ur) => baseAPI.post(router, ur),
  update: (ur) => baseAPI.put(router, ur),
  delete: (id) => baseAPI.delete(`${router}/${id}`),
  list: () => baseAPI.get(router),
  getByUf: (uf) => baseAPI.get(`${router}/obter-por-uf/${uf}`),
  get: (id) => baseAPI.get(`${router}/${id}`)
}
