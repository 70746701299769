<template>
  <v-select
    id="indicador-select"
    v-model="filter.indicador"
    item-name="indicadorSubgrupoId"
    item-text="nome"
    :items="indicadores"
    label="Indicador"
    placeholder="Selecione um Indicador"
    :return-object="true"
    :disabled="disabled"
    @change="$emit('changeIndicador')"
  >
    <template
      slot="item"
      slot-scope="data"
    >
      {{ data.item.indicadorGrupo.nome }} - {{ data.item.nome }}
    </template>
    <template slot="no-data">
      Nenhum Indicador retornado
    </template>
  </v-select>
</template>

<script>
import { indicadorSubgrupoAPI } from '@/modules/indicadores'

export default {
  name: 'AppIndicadorCombobox',
  props: {
    filter: { type: Object, required: true },
    disabled: { type: Boolean, required: false, default: false }
  },
  data () {
    return {
      indicadores: []
    }
  },
  mounted () {
    this.getIndicadores()
  },
  methods: {
    getIndicadores () {
      indicadorSubgrupoAPI.list()
        .then(resp => {
          this.indicadores = resp.data
        })
        .catch(() => {
          this.$notification.error('Erro ao listar indicadores')
        })
    }
  }
}
</script>
