/* eslint-disable indent */
import baseAPI from '@/http'

const router = 'api/municipio/municipio'

export const municipioAPI = {
    create: (municipio) => baseAPI.post(router, municipio),
    update: (municipio) => baseAPI.put(router, municipio),
    delete: (id) => baseAPI.delete(`${router}/${id}`),
    list: (uf) => baseAPI.get(`${router}` + `/get-municipios/${uf}`),
    listByUf: (ufs) => baseAPI.get(`${router}` + `/get-municipios-by-uf/${ufs}`),
    listURByUfs: (ufs) => baseAPI.get(`${router}/get-unidades-regionais-by-uf/${ufs}`,),
    get: (id) => baseAPI.get(`${router}/${id}`),
    getMapa: (uf) => baseAPI.get(`${router}/get-mapa/${uf}`),
    getIndicadores: (id) => baseAPI.get(`${router}/get-by-indicador-subgrupo/${id}`),
    getIndicadoresMunicipio: (id) => baseAPI.get(`${router}/get-indicador-by-municipio/${id}`),
    getMunicipiosNA: () => baseAPI.get(`${router}/get-mapa-fixo-na`),
    getMunicipiosCE: (uf) => baseAPI.get(`${router}/get-municipios/${uf}`),
    getByRegiao: (ids) => baseAPI.get(`${router}/get-by-regiao-sebrae?ids=${ids}`),
    getByProjeto: (ids) => baseAPI.get(`${router}/get-munipio-by-projeto?ids=${ids}`),
    getProjetos: (codigos) => baseAPI.get(`${router}/get-projetos?Codigos=${codigos}`),
    getMapaFixo: () => baseAPI.get(`${router}` + '/get-mapa-fixo'),
    getConsultores: () => baseAPI.get(`${router}` + '/consultores'),
    getProjetosTodos: () => baseAPI.get(`${router}` + '/get-todos-projetos'),
    getSolucoes: () => baseAPI.get(`${router}` + '/get-solucoes'),
    getByRegional: (regionalids) => baseAPI.get(`${router}` + '/get-by-regional/' + `${regionalids}`),
    getRegionais: () => baseAPI.get(`${router}` + '/get-regionais'),
    getStatus: () => baseAPI.get(`${router}` + '/status-relacionamento'),
    getContrato: () => baseAPI.get(`${router}` + '/status-contrato'),
    getByProjeto1: (projetoids, regionalids) => baseAPI.get(`${router}` + '/get-by-projeto/' + `${projetoids}` + '/' + `${regionalids}`),
    getByIndicadores: (perguntaId, projetoids, regionalids, tipoFiltragem) => baseAPI.post(`${router}` + '/get-by-indicadores/' + `${perguntaId}` + '/' + `${projetoids}` + '/' + `${regionalids}/${tipoFiltragem}`),
    getExecucao: (unidaderegionalIds, projetoIds, municipioids, solucaoids, usuarioids, municipiosolucaoNome) => baseAPI.get(`${router}` + '/get-execucao/' + `${unidaderegionalIds}` + '/' + `${projetoIds}` + '/' + `${municipioids}` + '/' + `${solucaoids}` + '/' + `${usuarioids}` + '/' + `${municipiosolucaoNome}`),
    getExecucaoAgrupada: (unidaderegionalIds, projetoIds, municipioids, solucaoids, usuarioids, municipiosolucaoNome) => baseAPI.get(`${router}` + '/get-execucao-agrupada/' + `${unidaderegionalIds}` + '/' + `${projetoIds}` + '/' + `${municipioids}` + '/' + `${solucaoids}` + '/' + `${usuarioids}` + '/' + `${municipiosolucaoNome}`),
    getEixos: (uf) => baseAPI.get(`${router}` + `/get-eixos/${uf}`),
    getMunicipiosByEixo: (eixosId) => baseAPI.get(`${router}` + `/municipios-by-eixo?eixoId=${eixosId}`),
    gerarXlsx: (filtro) => baseAPI.get(`${router}` + `/gerar-xlsx-eixos/${filtro.uf}/${filtro.statusId}/${filtro.statusContratoId}/${filtro.eixoId}/${filtro.ur}/${filtro.eixoTipo}/${filtro.indicadorTipo}`, { responseType: 'blob' }),
    gerarXlsxIndicador: (filtro) => baseAPI.get(`${router}` + `/gerar-xlsx-Indicador/${filtro.uf}/${filtro.statusId}/${filtro.statusContratoId}/${filtro.eixoId}/${filtro.ur}/${filtro.indicador}/${filtro.eixoTipo}/${filtro.indicadorTipo}`, { responseType: 'blob' }),
    gerarPdfEixos: (filtro) => baseAPI.get(`${router}` + `/gerar-pdf-eixos/${filtro.uf}/${filtro.statusId}/${filtro.statusContratoId}/${filtro.eixoId}`, { responseType: 'blob' }),
    gerarPdfIndicador: (filtro) => baseAPI.get(`${router}` + `/gerar-pdf-Indicador/${filtro.uf}/${filtro.statusId}/${filtro.statusContratoId}/${filtro.eixoId}`, { responseType: 'blob' }),
    gerarRelatoriogSituacaoGeral: (uf) => baseAPI.get(`${router}` + `/gerar-xlsx-situacao-geral/${uf}`, { responseType: 'blob' }),
    gerarRelatorioParceirosCriterios: (uf) => baseAPI.get(`${router}` + `/gerar-xlsx-parceiros-criterios/${uf}`, { responseType: 'blob' }),
    getRelatorioSituacaoGeral: (uf) => baseAPI.get(`${router}` + `/get-situacao-geral/${uf}`),
    getRelatorioParceirosCriterios: (uf) => baseAPI.get(`${router}` + `/get-parceiros-criterios/${uf}`),
    getRelatorioEixosSelo: (filtro) => baseAPI.get(`${router}` + `/get-eixos-selo/${filtro.uf}/${filtro.statusId}/${filtro.statusContratoId}/${filtro.eixoId}/${filtro.eixoTipo}`),
    getRelatorioIndicadorSelo: (filtro) => baseAPI.get(`${router}` + `/get-indicador-selo/${filtro.uf}/${filtro.statusId}/${filtro.statusContratoId}/${filtro.eixoId}/${filtro.eixoTipo}`),
    getQuantidadeSelosAtribuidos: (uf) => baseAPI.get(`${router}` + `/get-quantidade-selos-atribuidos/${uf}`),
    gerarRelatorioQuantidadeSeloAtribuidos: (uf) => baseAPI.get(`${router}` + `/gerar-xlsx-quantidade-selos-atribuidos/${uf}`, { responseType: 'blob' }),
    gerarQuantidadeParceiros: (filtro) => baseAPI.post(`${router}` + `/get-quantidade-parceiros`, filtro),
    gerarRelatorioQuantidadeParceiros: (filtro) => baseAPI.post(`${router}` + `/gerar-xlsx-quantidade-parceiros-por-uf`, filtro, { responseType: 'blob' }),
    gerarXlsEixoPeriodo: (filtro) => baseAPI.get(`${router}` + `/gerar-xlsx-eixos-por-periodo/${filtro.uf}/${filtro.ur}`, { responseType: 'blob' }),
    // eslint-disable-next-line eol-last
}