<template>
  <div class="seloHeader" v-if="isSala">
    <div class="tituloSelo">Selo Sebrae de Referência em Atendimento</div>
    <div class="medalhas">
      <img
        src="https://slide1-images.s3.amazonaws.com/public/selo-medalha/seloMedalhaBronze.png"
      />
      <img
        src="https://slide1-images.s3.amazonaws.com/public/selo-medalha/seloMedalhaPrata.png"
      />
      <img
        src="https://slide1-images.s3.amazonaws.com/public/selo-medalha/seloMedalhaOuro.png"
      />
      <img
        src="https://slide1-images.s3.amazonaws.com/public/selo-medalha/seloMedalhaDiamante.png"
      />
    </div>
  </div>
</template>
<script>
import { mapGetters } from "vuex";

export default {
  computed: {
    ...mapGetters(["isSala", "isFullAdmin"]),
  },
};
</script>

<style lang="scss" scoped>
@import "@/variables.scss";
.seloHeader {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
}
.tituloSelo {
  color: $cor-selo-texto;
  font-weight: bold;
}
.medalhas {
  display: flex;
  align-items: center;
  img {
    width: 70px;
  }
}
</style>
