<template>
  <v-container>
    <v-dialog
      v-model="exibeModal"
      scrollable
    >
      <v-card>
        <v-card-title
          class="modal-header elevation-10"
        >
          <v-col>
            <slot name="title">
              {{ municipio.nome }}
            </slot>
          </v-col>
          <v-col class="text-right">
            <v-icon
              color="white"
              @click="$emit('closeModal')"
            >
              mdi-window-close
            </v-icon>
          </v-col>
        </v-card-title>

        <v-card-text>
          <v-row>
            <v-col>
              <span v-if="municipio.regiao">
                <b>Região: </b> {{ municipio.regiao.nome }}
              </span>
              <span v-if="municipio.projeto">
                <b>Projeto: </b> {{ municipio.projeto }}
              </span>
            </v-col>

            <v-col class="text-right">
              <v-btn
                color="primary"
                @click="relatorio()"
              >
                GERAR RELATÓRIO
              </v-btn>
            </v-col>
          </v-row>

          <v-row class="rowIndicadoresMunicipais">
            <v-col>
              <h3>INDICADORES MUNICIPAIS</h3>
            </v-col>
          </v-row>

          <v-row>
            <v-col>
              <v-tabs
                v-model="tab"
                background-color="primary"
                vertical
                left
              >
                <v-tab
                  v-for="item in indicadores"
                  :key="item.indicadorId"
                  center
                  @click="getCabecalho(item.indicadorSubgrupoId)"
                >
                  {{ item.nome }}
                </v-tab>

                <v-tab-item
                  v-for="item in indicadores"
                  :key="item.indicadorId"
                >
                  <v-row>
                    <v-col>
                      <v-data-table
                        :headers="cabecalhoIndicadores"
                        :items="indicadoresMunicipio"
                        :disable-pagination="true"
                        :hide-default-footer="true"
                      />
                      <!-- <table>
                        <thead>
                          <th v-for="indicador in cabecalhoIndicadores" :key="indicador.text">
                            {{ indicador.text }}
                          </th>
                        </thead>

                        <tbody>
                          <tr>
                            <td v-for="indicador in cabecalhoIndicadores" :key="indicador.value">
                              {{ indicadoresMunicipio[indicador.value] }}
                            </td>
                          </tr>
                        </tbody>
                      </table> -->
                    </v-col>
                  </v-row>
                </v-tab-item>
              </v-tabs>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import { indicadorAPI, indicadorSubgrupoAPI } from '@/modules/indicadores'
import { municipioAPI } from '@/modules/municipio'

export default {
  props: {
    municipio: { type: Object, required: false },
    exibeModal: { type: Boolean, required: true }
  },
  data () {
    return {
      cabecalhoIndicadores: [],
      indicadores: [],
      indicadoresMunicipio: [],
      tab: {}
    }
  },
  watch: {
    exibeModal: function (value) {
      if (value) {
        this.getIndicadoresMunicipio()
        this.getIndicadores()
      }
    }
  },
  methods: {
    getIndicadores () {
      indicadorSubgrupoAPI.list()
        .then(resp => {
          this.indicadores = resp.data
          this.getCabecalho(this.indicadores[0].indicadorSubgrupoId)
        })
        .catch(() => {
          this.$notification.error('Erro ao listar dados')
        })
    },
    getCabecalho (indicadorSubgrupoId) {
      indicadorAPI.getCabecalhoTabela(indicadorSubgrupoId)
        .then(resp => {
          this.cabecalhoIndicadores = resp.data
          this.$forceUpdate()
        })
        .catch(() => {
          this.$notification.error('Erro ao listar dados')
        })
    },
    getIndicadoresMunicipio () {
      municipioAPI.getIndicadoresMunicipio(this.municipio.municipioId)
        .then(resp => {
          this.indicadoresMunicipio.push(resp.data)
          this.$forceUpdate()
        })
        .catch(() => {
          this.$notification.error('Erro ao listar municípios')
        })
    },
    relatorio () {

    }
  }
}
</script>

<style lang="scss">
.rowIndicadoresMunicipais {
  margin-left: -24px;
  margin-right: -24px;
  margin-top: 20px;
  text-align: center;
  color: #fff;
  background-color: #086a87;
}
</style>
