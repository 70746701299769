export default {
  setLoading({ commit }, payload) {
    commit('SET_LOADING', payload)
  },
  setToken({ commit }, payload) {
    commit('SET_TOKEN', payload)
  },
  setUser({ commit }, payload) {
    commit('SET_USER', payload)
  },
  setAdmin({ commit }, payload) {
    commit('SET_ADMIN', payload)
  },
  logout({ commit }) {
    commit('LOGOUT')
  },
  setExpired({ commit }, payload) {
    commit('SET_EXPIRED', payload)
  },
  setLastRoute({ commit }, payload) {
    commit('SET_LAST_ROUTE', payload)
  },
  setUf({ commit }, payload) {
    commit('SET_UF', payload)
  },
  setIsSala({ commit }, payload) {
    commit('SET_ISSALA', payload)
  },
  setForcedLogout({ commit }, payload) {
    commit('SET_FORCED_LOGOUT', payload)
  },
  setLogo({ commit }, payload) {
    commit('SET_LOGO', payload)
  },
  setSvg({ commit }, payload) {
    commit('SET_SVG', payload)
  },
  setMapaInicial({ commit }, payload) {
    commit('SET_MAPA_INICIAL', payload)
  },
  setMapaVariables({ commit }, payload) {
    commit('SET_MAPA_VARIABLES', payload)
  },
  limparMapaFixo({ commit }) {
    commit('LIMPAR_MAPA_FIXO')
  },
  resetarMapaFixo({ commit }) {
    commit('RESETAR_MAPA_FIXO')
  }
}
