import baseAPI from '@/http'

export default {
  SET_USER(state, payload) {
    state.user = payload
    localStorage.setItem('user', JSON.stringify({ ...payload.user, expiration: payload.expiration }))
  },
  SET_UF(state, payload) {
    state.uf = payload
    localStorage.setItem('uf', payload)
  },
  SET_LOGO(state, payload) {
    state.logo = payload
    localStorage.setItem('logo', payload)
  },
  SET_SVG(state, payload) {
    state.svg = payload
    localStorage.setItem('svg', payload)
  },
  SET_LOADING(state, payload) {
    state.loading = payload
  },
  SET_TOKEN(state, payload) {
    state.token = payload
    state.isAuthenticated = true
    localStorage.setItem('token', payload)
  },
  LOGOUT: (state) => {
    state.isAuthenticated = false

    delete state.token
    localStorage.removeItem('token')

    delete state.user
    localStorage.removeItem('user')
    state.isAdmin = false

    delete baseAPI.defaults.headers.common['Authorization']
  },
  SET_ADMIN(state, payload) {
    state.isAdmin = payload
  },
  SET_EXPIRED(state, payload) {
    state.expired = payload
  },
  SET_LAST_ROUTE(state, payload) {
    state.lastRoute = payload
  },
  SET_MAPA_VARIABLES(state, payload) {
    state.mapa = { ...state.mapa, ...payload }
  },
  SET_MAPA_INICIAL(state, payload) {
    state.mapaFixoInicial = payload

  },
  RESETAR_MAPA_FIXO(state) {
    state.mapa = {
      mapaFixo: state.mapa.mapaFixo.map(item => ({
        ...item, class: 'a'
      })),
      totalMapaIndicadores: null,
      totalMapaMunicipio: null,
      totalMapaContratos: null,
      totalMapaRelacionamentos: null,
      totalMapaUfs: null,
      filter: {
        indicador: {},
        contratos: [],
        relacionamentos: [],
        municipio: {},
        uf: '',
        eixoTipo: 'OU'
      }
    }
  },
  SET_ISSALA(state, payload) {
    state.isSala = payload
  },
  SET_FORCED_LOGOUT(state, payload) {
    localStorage.setItem('forcedLogout', payload)
    state.forcedLogout = payload
  },
  LIMPAR_MAPA_FIXO(state) {
    state.mapa = {
      mapaFixo: state.mapa.mapaFixo.map(item => ({
        ...item, class: null
      })),
      totalMapaIndicadores: null,
      totalMapaMunicipio: null,
      totalMapaContratos: null,
      totalMapaRelacionamentos: null,
      totalMapaUfs: null,
      filter: {
        indicador: {},
        contratos: [],
        relacionamentos: [],
        municipio: {},
        uf: '',
        eixoTipo: 'OU'
      }
    }
  }

}
