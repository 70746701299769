<template>
  <v-select
    id="select"
    v-model="filter.indicador"
    item-text="nome"
    item-name="indicadorId"
    item-value="indicadorId"
    :items="indicadores"
    label="Indicador"
    placeholder="Indicador"
    :disabled="disabled"
    @change="$emit('onChange')"
  >
    <template slot="no-data">
      Nada retornado
    </template>
  </v-select>
</template>

<script>
// import { subGrupoIndicadorAPI } from '../API/subGrupoIndicadorAPI'
import { mapActions } from 'vuex'

export default {
  name: 'AppIndicadorCombobox',
  props: {
    filter: { type: Object },
    disabled: { type: Boolean, default: false }
  },
  mounted () {
    this.load()
  },
  methods: {
    ...mapActions([
      'setLoading'
    ]),
    load () {
      /*
      subGrupoIndicadorAPI.list()
        .then(resp => {
          this.subGrupoIndicador = resp.data
        })
        .catch()
      */
    }
  },
  data () {
    return {
      indicadores: []
    }
  }
}
</script>
