export default {
  isAuthenticated: state => state.isAuthenticated,
  isAdmin: state => state.isAdmin,
  loading: state => state.loading,
  token: state => state.token,
  user: state => state.user,
  expired: state => state.expired,
  lastRoute: state => state.lastRoute,
  uf: state => state.uf,
  logo: state => state.logo,
  svg: state => state.svg,
  mapa: state => state.mapa,
  isSala: state => state.isSala
}
