<template>
  <v-autocomplete
    id="municipio-select"
    v-model="filter.municipio"
    item-text="nome"
    item-value="codigoIbge"
    :items="municipios"
    :label="isSala ? 'Parceiros' : 'Municípios'"
    placeholder="Selecione..."
    :return-object="true"
    :disabled="disabled"
    @change="emitValue"
    :multiple="isMultiple"
    :solo="solo"
    :loading="loading"
    hide-details="auto"
  >
    <template slot="no-data">
      <div v-if="loading">
        <v-col align-self="center"
          ><div class="progress" v-if="loading">
            <v-progress-circular
              indeterminate
              color="primary"
            ></v-progress-circular></div
        ></v-col>
      </div>
      <div v-else>Nenhum Município retornado</div>
    </template>
  </v-autocomplete>
</template>

<script>
import { municipioAPI } from "@/modules/municipio";
import { mapGetters } from "vuex";

export default {
  name: "MunicipioCombobox",
  props: {
    filterMunicipios: { type: Array, default: () => [] },
    filter: { type: Object, required: true },
    disabled: { type: Boolean, required: false, default: false },
    solo: { type: Boolean, required: false, default: false },
    uf: { type: String, default: "" },

    isMultiple: { type: Boolean, required: false, default: false },
  },
  computed: {
    ...mapGetters(["isSala"]),
  },
  data() {
    return {
      todomunicipios: [],
      municipios: [],
      loading: true,
    };
  },
  watch: {
    uf: {
      handler: function (newValue) {
        console.log("newValue: ", newValue);
        if (newValue) this.list();
      },
      deep: true,
    },
  },
  mounted() {
    this.list();
  },
  methods: {
    emitValue(e) {
      this.$emit("changeMunicipio", e);
    },
    list() {
      municipioAPI
        .listByUf(this.uf)
        .then((resp) => {
          this.todomunicipios = resp.data;
          this.municipios = resp.data;
          this.loading = false;
        })
        .catch(() => {
          this.loading = false;
          this.$notification.error("Erro ao listar municípios");
        });
    },
  },
};
</script>
