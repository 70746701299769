import baseAPI from '@/http'

const router = 'api/indicador/indicadorSubgrupo'

export const indicadorSubgrupoAPI = {
  create: (indicador) => baseAPI.post(router, indicador),
  update: (indicador) => baseAPI.put(router, indicador),
  delete: (id) => baseAPI.delete(`${router}/${id}`),
  list: () => baseAPI.get(router),
  get: (id) => baseAPI.get(`${router}/${id}`),
  getCabecalho: () => baseAPI.get(`${router}/get-cabecalho`)
}
