import axios from 'axios'
import qs from 'qs'
import { atualUfDefinition } from '../helpers/utils'

let http = axios.create({
  baseURL: atualUfDefinition.env
})


http.interceptors.request.use(
  (config) => {
    const token = localStorage.getItem('token')
    config.headers['banco'] = atualUfDefinition.banco
    config.headers['uf'] = atualUfDefinition.ufEmail
    if (token) {
      config.headers['Authorization'] = `Bearer ${token}`
    }

    return config
  },
  (error) => {
    return Promise.reject(error)
  }
)

http.stringify = qs.stringify

export default http
