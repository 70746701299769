import 'babel-polyfill'
import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './vuex/store'
import vuetify from './plugins/vuetify'
import http from './http'
import VueEllipseProgress from 'vue-ellipse-progress';
import VueNotification from '@kugatsu/vuenotification'

Vue.prototype.$http = http
Vue.use(VueEllipseProgress);
Vue.use(VueNotification, { timer: 5 })

new Vue({
  router,
  store,
  vuetify,
  render: h => h(App)
}).$mount('#app')
