import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSelect } from 'vuetify/lib/components/VSelect';
import { VTextField } from 'vuetify/lib/components/VTextField';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c(VContainer,[_c(VDialog,{attrs:{"scrollable":""},model:{value:(_vm.exibeModal),callback:function ($$v) {_vm.exibeModal=$$v},expression:"exibeModal"}},[_c(VCard,[_c(VCardTitle,{staticClass:"modal-header elevation-10"},[_c(VCol,[_vm._t("title",function(){return [_vm._v(" Adicionar Indicador ")]})],2),_c(VCol,{staticClass:"text-right"},[_c(VIcon,{attrs:{"color":"white"},on:{"click":function($event){return _vm.$emit('closeModal')}}},[_vm._v(" mdi-window-close ")])],1)],1),_c(VCardText,[_c(VRow,[_c(VCol,{attrs:{"cols":"4"}},[_c(VTextField,{attrs:{"label":"Nome"},model:{value:(_vm.indicador.nome),callback:function ($$v) {_vm.$set(_vm.indicador, "nome", $$v)},expression:"indicador.nome"}})],1),_c(VCol,{attrs:{"cols":"4"}},[_c(VTextField,{attrs:{"label":"Fonte"},model:{value:(_vm.indicador.fonte),callback:function ($$v) {_vm.$set(_vm.indicador, "fonte", $$v)},expression:"indicador.fonte"}})],1),_c(VCol,{attrs:{"cols":"4"}},[_c(VTextField,{attrs:{"label":"Relatorio"},model:{value:(_vm.indicador.relatorio),callback:function ($$v) {_vm.$set(_vm.indicador, "relatorio", $$v)},expression:"indicador.relatorio"}})],1)],1),_c(VRow,[_c(VCol,{attrs:{"cols":"4"}},[_c(VTextField,{attrs:{"label":"Texto"},model:{value:(_vm.indicador.texto),callback:function ($$v) {_vm.$set(_vm.indicador, "texto", $$v)},expression:"indicador.texto"}})],1),_c(VCol,{attrs:{"cols":"4"}},[_c(VSelect,{attrs:{"items":_vm.subgrupos,"item-text":"nome","item-value":"indicadorSubgrupoId","label":"Subgrupos","placeholder":"Selecione um Subgrupo"},model:{value:(_vm.indicador.indicadorSubgrupoId),callback:function ($$v) {_vm.$set(_vm.indicador, "indicadorSubgrupoId", $$v)},expression:"indicador.indicadorSubgrupoId"}})],1),_c(VCol,{attrs:{"cols":"4"}},[_c(VBtn,{staticClass:"primary",on:{"click":_vm.salvar}},[_vm._v(" Salvar ")])],1)],1)],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }