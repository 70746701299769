<template>
  <v-container
    id="ur-list"
  >
    <v-row>
      <v-col
        class="text-right"
      >
        <app-modal-insert
          :edit="false"
          :object="null"
          :color="'success'"
          :icon="'mdi-plus-circle'"
          :grupo-id="grupo"
          @on-save="reload()"
        >
          <template
            v-slot:title
            :edit="false"
          >
            Cadastrar
          </template>
        </app-modal-insert>
      </v-col>
    </v-row>

    <v-row>
      <v-col>
        <v-data-table
          :headers="headers"
          :items="subGrupos"
          class="elevation-1"
          hide-default-footer
          disable-pagination
        >
          <template v-slot:item.nome="{ item }">
            <span>
              {{ item.nome }}
            </span>
          </template>

          <template v-slot:item.acoes="{ item }">
            <v-row>
              <v-col
                cols="1"
              >
                <app-modal-insert
                  :object="item"
                  :edit="true"
                  :color="'warning'"
                  :icon="'mdi-pencil'"
                  :grupo-id="grupo"
                  @on-save="reload()"
                >
                  <template v-slot:title>
                    Editar SubGrupo
                  </template>
                </app-modal-insert>
              </v-col>
            </v-row>
          </template>

          <template v-slot:item.acoes_delete="{ item }">
            <v-row>
              <v-col
                cols="1"
              >
                <app-modal-delete
                  :object="item"
                  @on-save="reload()"
                >
                  <template v-slot:title>
                    Deletar
                  </template>
                </app-modal-delete>
              </v-col>

              <v-col
                cols="1"
              />
            </v-row>
          </template>

          <template slot="no-data">
            <span>
              Nada retornado
            </span>
          </template>
        </v-data-table>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import AppModalInsert from './ModalInsert'
import { mapActions, mapGetters } from 'vuex'
import AppModalDelete from './../components/ModalDelete'

export default {
  components: {
    AppModalInsert,
    AppModalDelete
  },
  props: {
    filter: { type: Object, required: true },
    subGrupos: { type: Array, required: true },
    grupo: { type: Number, required: true }
  },
  data () {
    return {
      headers: [
        { text: 'SubGrupo', value: 'nome' },
        { text: 'Editar', value: 'acoes', sortable: false },
        { text: 'Excluir', value: 'acoes_delete', sortable: false }
      ],
      payload: []
    }
  },
  methods: {
    ...mapActions([
      'setLoading',
      'setConsultorCondutorMunicipio',
      'setConsultorEspecialistaMunicipio',
      'setAnalistaMunicipio',
      'setADMunicipio'
    ]),
    reload () {
      this.$emit('reload', this.subGrupos)
    }
  },
  mounted () {
    this.reload()
  },
  computed: {
    ...mapGetters([
      'isAdmin',
      'isConsultorCondutorMunicipio',
      'isConsultorEspecialistaMunicipio',
      'isADMunicipio',
      'isAnalistaMunicipio',
      'isAuthenticated',
      'isSPFesp',
      'isSPFespEspecial',
      'isSPCoordenador'
    ])
  }
}
</script>
