<template>
  <v-dialog
    v-model="dialog"
    persistent
    max-width="600"
  >
    <template v-slot:activator="{ on }">
      <v-btn
        outlined
        color="error"
        class="mr-3"
        v-on="on"
      >
        <v-icon>mdi-trash-can-outline</v-icon>
      </v-btn>
    </template>
    <v-card>
      <v-card-title
        class="modal-header elevation-10"
      >
        <v-row>
          <v-col>
            <slot name="title" />
          </v-col>
          <v-col class="text-right">
            <v-icon
              color="white"
              @click="dialog = false"
            >
              mdi-window-close
            </v-icon>
          </v-col>
        </v-row>
      </v-card-title>
      <v-card-text>
        <v-row>
          <v-col>
            <h2>Confirma que deseja deletar este item?</h2>
          </v-col>
        </v-row>
      </v-card-text>
      <v-card-actions>
        <div class="flex-grow-1" />
        <v-btn
          color="error"
          outlined
          text
          @click="dialog = false"
        >
          Fechar
        </v-btn>
        <v-btn
          color="info"
          outlined
          text
          @click="onConfirm"
        >
          Deletar
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { subGrupoIndicadorAPI } from '../API/subGrupoIndicadorAPI'

export default {
  name: 'AppModalDelete',
  props: {
    object: { type: Object, required: true }
  },
  data () {
    return {
      payload: {},
      dialog: false
    }
  },
  mounted () {
    if (this.object) {
      this.payload = this.object
    }
  },
  methods: {
    onConfirm () {
      subGrupoIndicadorAPI.delete(this.payload.indicadorSubgrupoId)
        .then(() => {
          this.dialog = false
          this.$emit('on-save')
        })
        .catch()
    }
  }
}
</script>
